.bookings-container {
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media only screen and (max-width: 480px) {
  .bookings-container {
    grid-template-columns: 1fr;
  }
}

.booking {
  padding: 1rem 2rem;
  gap: 0.5rem;
  max-width: 576px;
  width: 90%;
  margin: auto;
  border-radius: 8px;
  box-sizing: border-box;
  transition: 0.25s;
}

.booking:hover {
  box-shadow: var(--secondary-color-black) 0px 0px 16px;
}

.booking-detail {
  justify-content: space-between;
  transition: 0.25s;
}

.booking-detail:hover {
  color: var(--primary-color-yellow);
}