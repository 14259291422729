#about {
  padding: 5rem 0;
}

.about-container {
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}

@media only screen and (max-width: 768px) {
  .about-container {
    grid-template-columns: 1fr;
  }
}

.about-images {
  flex-direction: column;
  gap: 1rem;
}

.about-images > img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 16px;
}

.about-heading {
  padding-bottom: 1rem;
}
