.home-hero-container {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 2rem;
}

.hero-heading > p {
  padding: 1rem 0;
}

.homehero-button {
  padding: 0.5rem 1rem;
  border: 2px solid var(--primary-color-yellow);
}

.home-hero-image {
  overflow: visible;
}

.home-hero-image > img {
  width: 100%;
  height: 20rem;
  object-fit: cover;
  object-position: center;
  border-radius: 16px;
  position: relative;
  top: 2.5rem;
}

@media only screen and (max-width: 768px) {
  .home-hero-container {
    grid-template-columns: 1fr;
    grid-template-rows: 20rem;
  }

  .home-hero-image {
    display: none;
  }
}
