#reviews {
  padding: 2.5rem 0;
}

.customer-reviews {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

@media only screen and (max-width: 768px) {
  .customer-reviews {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 480px) {
  .customer-reviews {
    grid-template-columns: 1fr;
  }
}

.testimonial {
  flex-direction: column;
  justify-content: space-evenly;
  gap: 0.5rem;
  text-align: center;
  border-radius: 8px;
  padding: 1rem;
  transition: 0.25s;
}

.testimonial:hover {
  box-shadow: var(--secondary-color-black) 0px 0px 16px;
}

.testimonial-icon > img{
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.fa-star {
  transition: 0.1s;
}

.fa-star:hover {
  transform: scale(1.5);
}
