.booking-hero-container {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 14rem;
  gap: 2rem;
  padding-bottom: 2rem;
}

.booking-hero-image > img {
  box-sizing: border-box;
  width: 100%;
  height: 12rem;
  margin: 1rem 0;
  object-fit: cover;
  object-position: center;
  border-radius: 16px;
}

@media only screen and (max-width: 768px) {
  .booking-hero-container {
    grid-template-columns: 1fr;
  }

  .booking-hero-image {
    display: none;
  }
}
