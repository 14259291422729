:root {
  --title-font: 'Markazi Text', serif;
  --text-font: 'Karla', sans-serif;
  --primary-color-green: #495E57;
  --primary-color-yellow: #F4CE14;
  --primary-color-white: #f5f5f5;
  --secondary-color-orange: #EE9972;
  --secondary-color-pink: #FBDABB;
  --secondary-color-white: #EDEFEE;
  --secondary-color-black: #333333;
}

* {
  list-style-type: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--text-font);
  color: var(--secondary-color-black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.super-container {
  max-width: 960px;
  margin: auto;
}

.super-container > * {
  padding-left: 1rem;
  padding-right: 1rem;
}

.grid-container {
  display: grid;
}

.flex-container {
  display: flex;
  align-items: center;
}

.hero-heading {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bg-green {
  background-color: var(--primary-color-green);
}

.bg-yellow {
  background-color: var(--primary-color-yellow);
}

.bg-white {
  background-color: var(--primary-color-white);
}

.bg-black {
  background-color: var(--secondary-color-black);
}

.text-green {
  color: var(--primary-color-green);
}

.text-yellow {
  color: var(--primary-color-yellow);
}

.text-black {
  color: var(--secondary-color-black);
}

.text-orange {
  color: var(--secondary-color-orange);
}

.text-pink {
  color: var(--secondary-color-pink);
}

.text-white {
  color: var(--secondary-color-white);
}

.title, .subtitle {
  font-family: var(--title-font);
}

.title {
  font-size: 4rem;
  font-weight: 500;
}

.subtitle {
  font-size: 2.5rem;
  font-weight: 400;
}

.section-title {
  text-align: center;
  padding: 0 1rem 2.5rem 0;
}

.lead-text {
  font-size: 1.125rem;
  font-weight: 500;
}

.paragraph-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.medium-16 {
  font-size: 1rem;
  font-weight: 500;
}

.mdeium-18 {
  font-size: 1.125rem;
  font-weight: 500;
}

.bold-18 {
  font-size: 1.125rem;
  font-weight: 700;
}

.button {
  border-radius: 16px;
  transition: 0.5s;
}

.button:hover {
  background-color: var(--primary-color-green);
  color: var(--primary-color-yellow);
}

.food-image {
  transition: 0.5s;
}

.food-image:hover {
  transform: scale(1.05);
}
