#bookingform {
  padding-bottom: 2.5rem;
}

.login-message {
  border: 3px solid var(--secondary-color-orange);
  border-radius: 8px;
  padding: 1rem;
  margin: 0 2rem;
}

.message-container {
  padding: 1rem;
  max-width: 640px;
  margin: auto;
}

.login-link {
  transition: 0.25s;
}

.login-link:link, .login-link:visited {
  text-decoration: none;
  color: var(--primary-color-yellow);
}

.login-link:hover, .login-link:active {
  color: var(--secondary-color-orange);
}

.form-container {
  padding: 1rem;
  max-width: 640px;
  margin: auto;
}

.input-container {
  padding: 0.5rem 2rem 0 2rem;
}

.input-container > label {
  display: block;
  padding: 0.25rem;
}

.input-box {
  box-sizing: border-box;
  width: 100%;
  padding: 0.75rem;
  border: 2px solid var(--secondary-color-black);
  border-radius: 8px;
  outline: none;
  transition: 0.5s;
}

#timeH, #timeM {
  width: 48%;
}

#timeH {
  margin-right: 2%;
}

#timeM {
  margin-left: 2%;
}

legend.not-empty {
  color: var(--primary-color-yellow);
}

.input-box:focus, .input-box.not-empty {
  border-color: var(--primary-color-yellow);
}

.input-box.required, .input-box.invalid {
  border-color: var(--secondary-color-orange);
  color: var(--secondary-color-orange);
}

.seating-container {
  padding-bottom: 1rem;;
}

.seating-container > legend {
  padding-bottom: 0.5rem;
}

.seating-option {
  padding: 0.5rem 1rem;
}

.seating-option > input {
  margin-right: 1rem;;
}

.booking-button {
  padding: 0.75rem 2rem;
  margin: 0.5rem;
  border: 2px solid var(--primary-color-yellow);
}

.booking-button:disabled {
  opacity: 25%;
}

@media only screen and (max-width: 768px) {
  .booking-button {
    width: 100%;
  }
}

.status-block {
  padding: 1rem 2rem;
}
