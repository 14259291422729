.menu-control-filter {
  padding: 1rem;
}

.menu-filter-button {
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: 2px solid var(--primary-color-yellow);
}

.menu-filter-button:hover, .menu-filter-button.selected {
  background-color: var(--primary-color-yellow);
  color: var(--primary-color-green);
}

.menu-order-button {
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: 2px solid var(--primary-color-green);
}

.menu-order-button:hover, .menu-order-button.selected {
  background-color: var(--primary-color-green);
  color: var(--primary-color-white);
}

.menu-container {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.menu-item {
  grid-template-columns: 1fr 3fr;
  gap: 2rem;
  padding: 1rem;
}

@media only screen and (max-width: 480px) {
  .menu-item {
    grid-template-columns: 1fr;
  }
}

.menu-image > img {
  max-width: 100%;
  border-radius: 16px;
  object-fit: cover;
  object-position: center;
}

.menu-detail {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
}

.menu-heading {
  justify-content: space-between;
  margin-bottom: 1rem;
}