.navbar {
  font-size: 1.125rem;
  font-weight: 600;
}

.navbar-container {
  justify-content: space-between;
}

.navbar-logo {
  max-height: 3rem;
  padding: 0.5rem;
}

.navbar-list > li {
  display: block;
}

.navbar-link {
  display: block;
  padding: 1rem;
  transition: 0.25s;
}

.navbar-link:link, .navbar-link:visited {
  text-decoration: none;
  color: var(--secondary-color-black);
}

.navbar-link:hover, .navbar-link:active {
  color: var(--primary-color-yellow);
}

.navbar-toggle {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  color: var(--secondary-color-black);
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .navbar-toggle {
    display: flex;
  }

  .navbar-list {
    background-color: var(--secondary-color-white);
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    transition: 0.25s;
  }

  .navbar-list.active {
    display: flex;
  }

  .navbar-list.inactive {
    display: none;
  }

  .navbar-list * {
    width: 100%;
  }

  .navbar-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
