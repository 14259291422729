#specials {
  padding: 5rem 0 2.5rem 0;
}

.weekly-specials {
  text-align: start;
  margin-left: 1rem;
}

.special-items {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;;
}

@media only screen and (max-width: 768px) {
  .special-items {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 480px) {
  .special-items {
    grid-template-columns: 1fr;
  }
}

.special-item {
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1rem;
  background-color: var(--secondary-color-white);
  border-radius: 16px;
}

.special-item > a:link, .special-item > a:visited {
  color: var(--secondary-color-black);
}

.card-image {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  overflow: hidden;
}

.card-image > img{
  width: 100%;
  height: 12rem;
  object-fit: cover;
  object-position: center;
}

.card-title {
  justify-content: space-between;
  padding: 1rem 1rem;
}

.card-title > h3 {
  transition: 0.25s;
}

.card-title > h3:hover {
  text-shadow: var(--primary-color-yellow) 0px 0px 8px;
}

.card-paragraph {
  padding: 1rem 1rem;
}

.highlight-text {
  border-radius: 8px;
  transition: 0.25s;
}

.card-paragraph:hover {
  .highlight-text {
    background-color: #F4CE14;
  }
}
